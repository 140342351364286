document.addEventListener("DOMContentLoaded", function(){
    // var navLinks = document.getElementsByClassName("nav-link");

    // for(i=0; i<navLinks.length; i++){
    //     navLinks[i].addEventListener("click", (e)=>ToggleActive(e));
    // };

    // function ToggleActive(link)
    // {
    //     var navLinks = document.getElementsByClassName("nav-link");

    //     alert(link.currentTarget.classList)
    //     for(i=0; i<navLinks.length; i++){
    //         navLinks[i].classList.remove("active");
    //     };

    //     link.currentTarget.className += ("active");
    // };

    // var cardToHover = document.getElementById("featureCard");

    // cardToHover.addEventListener("mouseenter", function(){
    //     cardToHover.classList.remove('shadow-lg')
    //     cardToHover.classList.add('shadow')
    // });

    // cardToHover.addEventListener("mouseleave", function(){
    //     cardToHover.classList.remove('shadow')
    //     cardToHover.classList.add('shadow-lg')
    // });
});

// for(i=0; i<navLinks.length; i++){
//     navLinks[i].classList.remove("active")
// }

// navLinksArr.forEach(link => { 
//     link.addEventListener("onclick", function() {
//         navLinks.forEach.removeClass("active")
//         this.addClass("active")
//         print("active")
//     });
// });